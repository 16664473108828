




























import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ExerciseForm from '@/components/ExercisePersonalize/index.vue';
import ExerciseFormLoading from '@/components/ExercisePersonalize/ExercisePersonalizeLoading.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ExerciseInstruction from './components/ExerciseInstruction/index.vue';

import ExerciseService from '@/services/Exercise/ExerciseService';
import BookService from '@/services/Book/BookService';

import { Book } from '@/globalInterfaces/Book';

@Component({
  components: {
    PageHeader,
    ExerciseInstruction,
    ExerciseForm,
    ExerciseFormLoading,
    BoxContainer,
    Footer,
    FeedbackUser,
  },
})
export default class Exercise extends Vue {
  private isLoadingExercise = true;

  private formData: Record<string, any> = {}
  private exercise: Record<string, any> = {}

  private ExerciseService = new ExerciseService();
  private BookService = new BookService();

  async created() {
    await this.getExercise();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async getExercise() {
    try {
      this.isLoadingExercise = true;

      const { fileID } = this.$route.params;

      const exercise = await this.ExerciseService.getExercise(Number(fileID));

      if (exercise && Object.entries(exercise).length) {
        this.exercise = exercise;
        this.formData = {
          howToShow: 'one',
          time: '5',
          qtdQuestions: String(this.exercise.questions.length),
        };
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o exercício.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingExercise = false;
    }
  }

  async getBookTitle() {
    const { path } = this.$route.params;
    const allBooksStore: Book[] = this.$store.getters.allBooks;

    const bookFromStore = this.findBookSlug(allBooksStore, path);

    if (bookFromStore) return bookFromStore?.title;

    const allBooksService: Book[] = await this.BookService.books(undefined, this.$store.getters.profile?.institution?.id);

    return this.findBookSlug(allBooksService, path)?.title;
  }

  findBookSlug(books: Book[], slug: string) {
    return books.find((book: Book) => (book.slug === slug));
  }

  async setBreadCrumbs() {
    const { context, path } = this.$route.params;
    if (context === 'planner') {
      this.setBreadCrumbsPlanner();
      return;
    }

    if (context === 'cursos') {
      this.setBreadCrumbsCourse();
      return;
    }

    const bookTitle = await this.getBookTitle();

    this.$breadcrumb.set([
      { title: 'cadernos', to: '/cadernos' },
      { title: bookTitle, to: `/caderno/${path}` },
      { title: 'exercício', to: null },
    ]);
  }

  setBreadCrumbsPlanner() {
    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      { title: 'exercício', to: null },
    ]);
  }

  setBreadCrumbsCourse() {
    const { path } = this.$route.params;

    const splitPath = path.split('&');
    const slugCourse = splitPath[0];
    const idCourses = splitPath[1];
    const idClass = splitPath[2];

    this.$breadcrumb.set([
      { title: 'cursos', to: `/cursos/${slugCourse}/${idCourses}/${idClass}` },
      { title: 'exercício', to: null },
    ]);
  }
}
