

























































































import { Component, Vue, Prop } from 'vue-property-decorator';

import RadioBox from '@/components/Input/RadioBox/RadioBox.vue';
import Select from '@/components/Input/Select/Select.vue';
import CheckboxList from '@/components/Input/CheckboxList/index.vue';

import Close from '@/assets/icons/Close.vue';

import BoxContainer from './components/index.vue';

@Component({
  components: {
    BoxContainer,
    RadioBox,
    Select,
    CheckboxList,
    Close,
  },
})
export default class ExercisePersonalize extends Vue {
  @Prop() completed!: false;
  @Prop() formData!: Record<string, any>;
  @Prop() exercise!: Record<string, any>;
  private timeList = {
    name: 'time',
    array: [
      {
        title: '3 minutos',
        value: 3,
        checked: false,
      },
      {
        title: '4 minutos',
        value: 4,
        checked: false,
      },
      {
        title: '5 minutos',
        value: 5,
        checked: true,
      },
    ],
  };
  private questionsShowedList = {
    name: 'howToShow',
    array: [
      {
        title: 'Uma por vez',
        value: 'one',
        checked: true,
      },
      {
        title: 'Quero que todas apareçam',
        value: 'all',
        checked: false,
      },
    ],
  };
  private howManyQuestionsList: Array<Record<string, string | number>> = [];
  private questionModelList = [
    { title: 'Objetiva' },
    { title: 'Subjetiva' },
    { title: 'V ou F' },
    { title: 'Relacionar Coluna' },
    { title: 'Somatório' },
  ];
  private questionTypeList = [
    { title: 'Enem' },
    { title: 'Medicina' },
    { title: 'Fuvest' },
    { title: 'Vestibulares' },
  ];
  private subjectList = [
    { title: 'Física', value: 'Física' },
    { title: 'Matemática', value: 'Matemática' },
    { title: 'Portugês', value: 'Português' },
    { title: 'Biologia', value: 'Biologia' },
  ];
  private topicList = [
    { title: 'Calculo 1', value: 'Calculo 1' },
    { title: 'Calculo 2', value: 'Calculo 2' },
    { title: 'Calculo 3', value: 'Calculo 3' },
    { title: 'Calculo 4', value: 'Calculo 4' },
    {
      title: 'Vetores e cálculos vetoriais complexos em X',
      value: 'Vetores e cálculos vetoriais complexos em X',
    },
  ];

  created() {
    this.setHowManyQuestions();
  }

  setHowManyQuestions() {
    this.exercise.questions.forEach(
      (question: Record<string, any>, index: number) => {
        this.howManyQuestionsList.unshift({
          title: this.setQuestionsTitle(index + 1),
          value: index + 1,
        });
      },
    );
  }

  setQuestionsTitle(index: number) {
    if (index === 1) {
      return `0${index} Questão`;
    }
    if (index < 10) {
      return `0${index} Questões`;
    }
    return `${index} Questões`;
  }

  setPage(): void {
    this.$router.push({
      path: '/exercicios/instrucoes',
      query: { from: this.$route.params.book, type: 'instructions' },
    });
  }

  setValueRadioBox(optionPicked: string, targetName: string) {
    this.formData[targetName] = optionPicked;
  }

  setValue(e: any) {
    this.formData[e.target.name] = e.target.value;
  }

  setCheckList(obj: Record<string, boolean>, name: string) {
    this.formData[name] = obj;
  }
}
