





















import { Component, Prop, Vue } from 'vue-property-decorator';
import Checkbox from '@/components/Input/Checkbox/index.vue';

@Component({
  components: {
    Checkbox,
  },
})
export default class CheckboxList extends Vue {
  @Prop() checkboxList!: Array<Record<string, any>>;
  @Prop() setCheckList!: Function;
  @Prop() name!: string;
  private checked: Record<string, boolean> = {};

  beforeMount() {
    this.checkboxList.forEach(((item: Record<string, any>) => {
      this.checked[item.title] = false;
    }));
  }

  setCheckbox(title: string) {
    this.checked[title] = !this.checked[title];
    this.setCheckList(this.checked, this.name);
  }
}
